<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="predictionData"
      item-key="_id"
      :items-per-page="5"
      show-select
      single-select
      show-expand
      single-expand
      v-model="selectedData"
      class="elevation-3"
      :loading="tableLoading"
      loading-text="ロード中。お待ちください"
    >
      <template v-slot:[`item.updated`]="{ item }">
        <div>{{ item.updated | dateFilter }}</div>
      </template>
      <template v-slot:[`item.makePrediction`]="{ item }">
        <div>{{ item.makePrediction | translateBoolean }}</div>
      </template>
      <template v-slot:[`item.printReceipt`]="{ item }">
        <div>{{ item.printReceipt | translateBoolean }}</div>
      </template>
    </v-data-table>

    <v-layout class="mt-6">
      <v-flex></v-flex>
    </v-layout>
  </div>
</template>

<script>
import firebase from "firebase";

export default {
  components: {},
  data: () => ({
    headers: [
      { text: "検診ID", value: "_id" },
      { text: "検診名", value: "examName" },
      { text: "更新日", value: "updated" },
      { text: "予診票", value: "makePrediction" },
      { text: "領収書印刷", value: "printReceipt" },
      { text: "", value: "data-table-expand" },
    ],
    predictionData: [],
    selectedData: [],
    tableLoading: false,
  }),
  created() {
    this.tableLoading = true;
    firebase
      .firestore()
      .collection("exam_data")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const addData = doc.data();
          addData._id = doc.id;
          this.predictionData.push(addData);
        });
        this.tableLoading = false;
      });
  },
  methods: {},
  watch: {
    selectedData: {
      handler(val) {
        this.$emit("selected-data", val);
      },
      deep: true,
    },
  },
  filters: {
    translateBoolean(ans) {
      return ans ? "あり" : "なし";
    },
    dateFilter(dateArg) {
      console.log(dateArg);
      const date = dateArg.toDate();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();
      return `${year}/${`00${month}`.slice(-2)}/${`00${day}`.slice(
        -2
      )} ${`00${hour}`.slice(-2)}:${`00${minute}`.slice(-2)}`;
    },
  },
};
</script>
