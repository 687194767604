<template>
  <div>
    <div class="d-print-none">
      <h2 class="pageTitle pt-5 mb-8">健診一覧</h2>
      <exam-list @selected-data="dataSelected" v-if="showAll"></exam-list>
      <v-row class="ml-6">
        <p class="pl-12 pt-6 caption">↑チェックしたファイル</p>
        <v-col cols="6">
          <v-select
            v-bind:items="todoList"
            v-model="whatdo"
            label="選択して下さい"
            solo
            return-object
            style="display: inline"
          ></v-select>
        </v-col>
        <p class="pt-6 caption">する</p>
        <v-btn
          class="ml-5 mt-4 blue accent-1"
          :disabled="!whatdo || !selectedData[0]"
          @click="runFunction"
          >実行</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="mt-3"
          style="margin-right: 60px"
          color="primary"
          large
          @click="$router.push({ name: 'makeExam' })"
          >新規作成</v-btn
        >
      </v-row>
      <v-card
        v-if="targetText"
        class="text-center mx-12 blue lighten-5 mb-12 pb-6"
      >
        <v-card-title>{{ targetText }}</v-card-title>
        <vue-qrcode
          :value="targetText"
          :options="option"
          tag="img"
        ></vue-qrcode>
        <v-row justify="end">
          <v-btn color="blue" dark class="mr-12 mt-6" large @click="printPushed"
            >印刷</v-btn
          >
        </v-row>
      </v-card>
    </div>
    <div v-if="targetText" class="only-print">
      <print-qRcode :url="targetText"></print-qRcode>
    </div>
  </div>
</template>

<script>
import examList from "@/components/examList.vue";
import printQRcode from "@/components/printQRcode.vue";
import firebase from "firebase";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import XLSX from "xlsx";
import saveAs from "file-saver";
import Datastore from "nedb";

export default {
  components: {
    examList,
    VueQrcode,
    printQRcode,
  },
  data: () => ({
    selectedData: [],
    db: {
      examiner: {},
      print: {},
    },
    todoList: [
      { text: "を編集", disabled: false },
      { text: "を削除", disabled: false },
      { text: "のURLを生成" },
      { text: "の受診者一覧をダウンロードする" },
      { text: "の印刷した人一覧をダウンロードする" },
    ],
    whatdo: "",
    showAll: true,
    inputText: "",
    targetText: "",
    option: {
      errorCorrectionLevel: "M",
      maskPattern: 0,
      margin: 10,
      scale: 2,
      width: 300,
      color: {
        dark: "#000000FF",
        light: "#FFFFFFFF",
      },
    },
  }),
  created() {
    this.db.examiner = new Datastore({
      filename: "examinerData.db",
    });
    this.db.examiner.loadDatabase();
    this.db.print = new Datastore({
      filename: "printData.db",
    });
    this.db.print.loadDatabase();
  },
  methods: {
    dataSelected(data) {
      this.selectedData = data;
    },
    runFunction() {
      if (this.selectedData.length > 0) {
        switch (this.whatdo.text) {
          case "を編集":
            this.editData();
            break;
          case "を削除":
            this.deleteData();
            break;
          case "のURLを生成":
            this.makeURL();
            break;
          case "の受診者一覧をダウンロードする":
            this.downloadExaminerData();
            break;
          case "の印刷した人一覧をダウンロードする":
            this.downloadPrintData();
            break;
          default:
            break;
        }
      }
    },
    editData() {
      const id = this.selectedData[0]._id;
      this.$router.push({ name: "makePrediction", query: { id } });
    },
    deleteData() {
      const f = window.confirm(
        `健診「${this.selectedData[0].title}」を削除します。よろしいですか？`
      );
      if (f) {
        firebase
          .firestore()
          .collection("exam_data")
          .doc(this.selectedData[0]._id)
          .delete()
          .then(() => {
            this.showAll = false;
            this.$nextTick(() => {
              this.showAll = true;
            });
          });
      }
    },
    makeURL() {
      this.targetText = `https://health.nenaiho.net/answerQuestions?id=${this.selectedData[0]._id}`;
    },
    downloadExaminerData() {
      let header = ["examId", "uid"];
      console.log(this.$store.getters.defaultQuestionsValue);
      header = header.concat(this.$store.getters.defaultQuestionsValue);
      this.db.examiner.find(
        { examId: this.selectedData[0]._id },
        (err, docs) => {
          if (docs[0]) {
            const ws = XLSX.utils.json_to_sheet(docs[0].data, {
              header,
            });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "sheet1");
            const wopts = {
              bookType: "xlsx",
              bookSST: false,
              type: "binary",
            };
            const wbOut = XLSX.write(wb, wopts);
            const blob = new Blob([this.s2ab(wbOut)], {
              type: "application/octet-stream",
            });
            const saveName = `${this.selectedData[0].examName}受診者一覧.xlsx`;
            saveAs(blob, saveName);
          } else {
            window.alert("受診者一覧がありません。");
          }
        }
      );
    },
    downloadPrintData() {
      let header = ["examId", "uid"];
      console.log(this.$store.getters.defaultQuestionsValue);
      header = header.concat(this.$store.getters.defaultQuestionsValue);
      this.db.print.find({ examId: this.selectedData[0]._id }, (err, docs) => {
        if (docs[0]) {
          const ws = XLSX.utils.json_to_sheet(docs[0].data, {
            header,
          });
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "sheet1");
          const wopts = {
            bookType: "xlsx",
            bookSST: false,
            type: "binary",
          };
          const wbOut = XLSX.write(wb, wopts);
          const blob = new Blob([this.s2ab(wbOut)], {
            type: "application/octet-stream",
          });
          const saveName = `${this.selectedData[0].examName}印刷した人一覧.xlsx`;
          saveAs(blob, saveName);
        } else {
          window.alert("印刷した人一覧がありません。");
        }
      });
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; i += 1) {
        view[i] = s.charCodeAt(i) & 0xff;
      }
      return buf;
    },
    printPushed() {
      window.print();
    },
  },
  watch: {},
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
.nodisplay {
  display: none;
}
@media print {
  .only-print {
    display: block;
  }
  .container {
    padding: 0 !important;
  }
  .v-main {
    padding: 0 !important;
  }
}
@media screen {
  .only-print {
    display: none;
  }
}
</style>
