<template>
  <div>
    <div class="printPageBody">
      <v-row justify="center">
        <h1>予診票解答用QRコード</h1>
      </v-row>
      <v-row justify="center">
        <vue-qrcode :value="url" :options="option" tag="img"></vue-qrcode>
      </v-row>
      <v-row justify="center">
        <p>{{ url }}</p>
      </v-row>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  components: {
    VueQrcode,
  },
  props: ["url"],
  data: () => ({
    option: {
      errorCorrectionLevel: "M",
      maskPattern: 0,
      margin: 10,
      scale: 2,
      width: 500,
      color: {
        dark: "#000000FF",
        light: "#FFFFFFFF",
      },
    },
  }),
  created() {},
  methods: {},
  watch: {},
  filters: {},
};
</script>
<style>
@media print {
  .container {
    padding: 0 !important;
  }
}
@page {
  size: A4;
  margin: 0;
}
.printPageBody {
  margin-top: 300px;
}
</style>
